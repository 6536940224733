<template>
  <section class="video-page">
    <h1>Video</h1>

    <ul>
      <li class="text-center">
        <video controls>
          <source src="https://fg.demostaging.ro/media/DoinaOltului.12541c0f.mp4" type="video/mp4">
        </video>
        <p>Doina Oltului</p>
      </li>
      <li class="text-center">
        <video controls>
          <source src="https://fg.demostaging.ro/media/Pasarea.78f2b6b9.mp4" type="video/mp4">
        </video>
        <p>Pasarea</p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'Video'
}
</script>

<style lang="scss">
.video-page {
  ul, p {
    max-width: 500px;
    margin: 0 auto;
    padding: 0;
  }
  li {
    margin-bottom: 40px;
  }
  p {
    margin-top: 8px;
    text-align: left;
    font-size: 1.8rem;
  }
  video {
    width: 100%;
  }
}
</style>
